<template>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-top:20px">
            <div class="col-md-6">
                <h3 class="page-header">
                <i class="fa fa fa-users animated bounceInDown show-info"></i>
                    View Defaulter Customers
                </h3>
            </div>
            <div class="col-md-6 ">
                <div class="row" style="padding-top:18px;justify-content:flex-end;">
                    <div class="col-md-2" style="margin-right:25px;">             
                        <button @click="refresh()" class="btn btn-primary" ><i class="fa fa-refresh"></i> Refresh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="col-md-12 info-box bg-warning text-white">
        <div class="row">
                <div class="col-md-4">
                    <div class="col-md-12 bg-primary-dark ">
                        <h4>Total EMI Amount <br> <i class="fa fa-inr"></i> 25500.00 </h4>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="col-md-12 bg-primary-dark ">
                        <h4>Total Receive EMI Amount <br> <i class="fa fa-inr"></i> 23599.95</h4>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="col-md-12 bg-primary-dark " style="background: #f0534f !important">
                        <h4>Loss Amount <br> <i class="fa fa-inr"></i> 1900.05 </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form action="/admin/viewdefalterlist" method="post">    
        <div class="row">
            <div class="col-md-12">
                <div class="panel panel-cascade">
                    <div class="panel-body ">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-horizontal cascde-forms">

                                    <div class="form-group row CHK" style="display:none;">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="NAME" name="NAME" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row CHK" style="display:none;">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Mobile<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="MOBILE" name="MOBILE" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Delivered By<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu" data-val="true" data-val-number="The field DEAL_BY must be a number." data-val-required="The DEAL_BY field is required." id="DEAL_BY" name="DEAL_BY"><option value="51">AISHA</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Entry Date : FROM<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="EDATEFROM" name="EDATEFROM" readonly="true" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Concession Date : FROM<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="CDATEFROM" name="CDATEFROM" readonly="true" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row CHK">
                                        <label class="col-lg-4 col-md-4 control-label"></label>
                                        <div class="col-lg-8 col-md-2">
                                            <input type="submit" value="Search" class="btn bg-primary text-white btn-lg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 CHK">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Customer LAN<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="LAN" name="LAN" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Concession By<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu" data-val="true" data-val-number="The field CONCESSIONBYID must be a number." data-val-required="The CONCESSIONBYID field is required." id="CONCESSIONBYID" name="CONCESSIONBYID"><option value="51">AISHA</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Plan<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu" data-val="true" data-val-number="The field PLAN must be a number." data-val-required="The PLAN field is required." id="PLAN" name="PLAN"><option value="1">EMI</option>
                                                <option value="2">SCHEME</option>
                                                <option selected="selected" value="0">SELECT</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Entry Date : TO<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="EDATETO" name="EDATETO" readonly="true" type="text" value="">
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Concession Date : TO<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" id="CDATETO" name="CDATETO" readonly="true" type="text" value="">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="row">
        <div class="col-md-12">
                <div class="panel panel-cascade">
                    <div class="panel-body ">
                        <table class="table table-bordered table-hover table-striped display">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>LAN</th>
                                    <th>NAME</th>
                                    <th>PRO. NAME</th>
                                    <th>SP</th>
                                    <th>DOWN PAY</th>
                                    <th>TOTAL EMI</th>
                                    <th>REC. EMI</th>
                                    <th style="background-color:#f0534f;color:#ffffff;font-weight: 800;">REM. EMI</th>
                                    <th>DEAL BY</th>
                                    <th>REMARK</th>
                                    <th>CONCESSION DATE</th>
                                    <th>ENTRY DATE</th>
                                    <th>CONCESSION BY</th>
                                    <th>ACTION</th>
                                </tr>
                            </thead>
                            <tbody class="table-font">
                                <tr v-for="(item,index) in defaultcustomers" v-bind:key="item.id">
                                    <td>{{index+1}}</td>
                                    <td></td>
                                    <td>{{item.name}}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td  style="padding:2px;padding-top:10px;">
                                        <button type="button" class="btn btn-danger btn-xs" style="padding:0px;"  @click="updatedefault(item)"> Restore</button>
                                     </td>
                                </tr>
                            </tbody>
                        </table>
        <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
        <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
        <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
        <img src="/Content/Admin/images/hourglass.gif" style="height:30px;width:30px;display:none;" class="LOADER">
        <div id="SHOWLAST"></div>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import Constants from '../../components/utilities/Constants.vue'
import { mapGetters } from 'vuex'
export default {
    mixins:[Constants],
    computed:{
		...mapGetters([
		    'customers','defaultcustomers'
		]),
	},
    mounted(){
		this.$store.dispatch('fetchdefaultcustomers')
	},
    methods: {
        refresh(){
            this.$store.dispatch('fetchdefaultcustomers')
        },
          updatedefault(item){
             if(item.isdefaulter==1){
                 let parm = {id:item.id,isdefaulter:item.isdefaulter==1?0:1};
                this.$http.post('api/user/makedefaulter',parm)
                .then((response) => {
                    this.$store.commit('updateappendcustomers',response.data);
                })
                .catch((err) => {
                    console.log(err)
                });
            }
        },
        
    },
}
</script>
